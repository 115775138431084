<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Surat Tugas </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateNews">
              <CRow>
                <CCol sm="8">
                  <div class="form-group">
                    <label style='clear:both;'>
                      
                      <CInputCheckbox
                        label="Schedule"
                        value="1"
                        @change="checkSchedule(1, $event)" 
                        @checked="showDate"
                        v-model="isSchedule" 
                      /> </label><br>
                    <date-picker v-model="tgl_berita" v-if="showDate" readonly value-type='timestamp' class='w-50' type="datetime"></date-picker>
                  </div>
                  <CInput
                    v-model="title"
                    label="Judul Berita"
                    placeholder="Input Judul Berita"
                  />
                  <div class="form-group">
                    <label>Berita</label>
                    <editor
                      v-model="details"
                      :api-key="apiKey"
                      :initial-value="details"
                      :init="editorSettings"
                      @onInit="handleEditorInit"
                    />
                  </div>
                  <!-- <div class="form-group">
                    <label>Berita Terkait</label>
                    <autocomplete
                        ref="autocomplete"
                        url="http://139.180.219.157/kasir/api/index"
                        anchor="product_name"
                        :classes="{ wrapper: 'form-wrapper', input: 'form-control', list: 'data-list', item: 'data-list-item' }"                    
                        param="q"
                        id="autocom"
                        label="writer"
                        :min="3"
                        :process = "processJSON"
                        :onSelect="getData">
                      </autocomplete>
                    </div> -->
                </CCol>
                <CCol sm="4">  
                  <img v-if="image_link.length>0" :src="image_link" class="img-thumbnail mb-3" />
                  <div class="form-group">
                    <label>Image</label>              
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" placeholder="Browse Image" @click="galeriModal = true; imageSourceClick = 'button'; initLoadImage();" v-model="image_link"  readonly />
                      <div class="input-group-append">
                        <button class="btn btn-sm btn-light" @click="galeriModal = true; imageSourceClick = 'button'; initLoadImage();"><CIcon name="cil-image" width="18" /></button>
                      </div>
                    </div>
                    <CInput
                      v-model="caption_image"
                      type="text"
                      label="Caption Image"
                      placeholder="Input Caption Image"
                    />
                  </div>
                  <div class="form-group">
                    <label>Rubrik</label>
                    <select v-model="category" class="form-control">
                      <option value="" disabled selected>-- Pilihan --</option>
                      <option v-for="o in categories" :value="o.id" :key="o.id">{{o.name}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Deskripsi</label>
                    <textarea v-model="deskripsi" class="form-control" @input="assertMaxChars()"></textarea>
                  </div>
                  <div class="form-group">
                    <label>Tag</label>
                    <tags-input 
                    element-id="tags"
                    v-model="source"
                    :existing-tags="tags2"
                    :add-tags-on-comma="true"
                    typeahead-style="dropdown"
                    :typeahead="true"></tags-input>
                    <!-- <multiselect v-model="source" @input="loadTags" :options="tags" :multiple="true" :taggable="true"></multiselect> -->
                  </div>
                  <CInput
                    v-model="author"
                    type="text"
                    label="Author"
                    placeholder="Input Author"
                  />
                  <CInput
                    v-model="editorName"
                    type="text"
                    label="Editor"
                    placeholder="Input Editor"
                  />
                  <div class="form-group">
                    <label>Slider</label>
                    <select v-model="slider" class="form-control">
                      <option value="" disabled selected>-- Pilihan --</option>
                      <option value="0" selected>Tidak</option>
                      <option value="1" >Ya</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Publish Berita</label>
                    <select v-model="status" class="form-control">
                      <option value="" disabled selected>-- Pilihan --</option>
                      <option value="0" selected>Tidak</option>
                      <option value="1" >Ya</option>
                    </select>
                  </div>
                  
                </CCol>
              </CRow>          
                    
              <div class="form-actions float-right">
                <CButton type="submit" color="primary" style='margin-right:15px' :disabled="!isSubmitable">{{submitString}}</CButton>
                <CButton color="secondary" to="/news">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>

    <CModal
      title="Modal title"
      size="xl"
      :show.sync="galeriModal"
    >
      <CInput
        class="mb-3"
        v-model="search"
        placeholder="Search Image"
      >
        <template #prepend-content><CIcon name="cil-magnifying-glass"/></template>
        <template #append>
          <CButton color="info" 
          @click="searchImage()">Search</CButton>
        </template>
      </CInput>
      <div class="row">
        <div class="col-md-3">
          <div class="card" style="height:295px;overflow:hidden" >
            <div class="card-img-top bg-light" style='height:148px;overflow:hidden' @click="setTinyContentNew()">
              <slot v-if="loadingUpload">
                <div class="loader"></div>
              </slot>
              <slot v-else>
                <input type='hidden' v-model="new_image" />
                <img :src="new_image_src" class="text-center" style='width:100%;margin: auto;display: block;' />
              </slot>
            </div>
            <div class="card-body" style="overflow: hidden;"   @click="setTinyContentNew()">
              <p class="card-text" ref="new_image_caption"  contenteditable="true">Ilustrasi</p>
            </div>
            <div>
              <div class="p-12 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" 
                  class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" />
              
                <label for="assetsFieldHandle" class="block cursor-pointer">
                  <div>
                    Drop files anywhere to upload 
                    or <span class="underline">click here</span> to upload
                  </div>
                </label>
                <!-- <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                  <li class="text-sm p-1" v-for="file in filelist">
                    ${ file.name }<button class="ml-2" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">remove</button>
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3" v-for="i in images" :key="i.id" >
          <div class="card" style="height:295px;overflow:hidden" @click="setTinyContent(i)">
            <img  :src="i.image" :alt="i.image_caption" class="card-img-top" />
            <div class="card-body">
              <p class="card-text">{{ i.image_caption }}</p>
            </div>
          </div>
        </div>
      </div>
      <template #header>
        <h6 class="modal-title">Galeri Foto</h6>
        <CButtonClose @click="galeriModal = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="galeriModal = false" >Cancel</CButton>
        <CButton @click="galeriModal = false" color="success">Save</CButton>
      </template>
    </CModal>
   
  </div>    
  
</template>
<style scoped>
#assetsFieldHandle {
  display: none;
}

.loader {
  margin: 30px auto;
  border: 10px solid #EAF0F6;
  border-radius: 50%;
  border-top: 10px solid #FF7A59;
  width: 70px;
  height: 70px;
  animation: spinner 4s linear infinite;
}
@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
<script>
require('@voerro/vue-tagsinput/dist/style.css');
require('vue2-autocomplete-js/dist/style/vue2-autocomplete.css');
import axios from 'axios';
import Multiselect from 'vue-multiselect'
import Editor from '@tinymce/tinymce-vue'
import Autocomplete from 'vue2-autocomplete-js'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueUploadComponent from 'vue-upload-component'
import VoerroTagsInput from '@voerro/vue-tagsinput';


let appName = null;

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  components: { 
    DatePicker,
    Autocomplete,
    Multiselect,
    'editor': Editor,
    "tags-input": VoerroTagsInput
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      app : null,
      loadingUpload:false,
      isSchedule: '',
      new_image: '',
      new_image_file: '',
      new_image_src: require('../../assets/images/no-pictures.png'),
      new_image_caption: '',
      filelist: [],
      search: "",
      galeriModal: false,
      apiKey: process.env.VUE_APP_TINY_API_KEY,
      myValue: "",
      nama_upt : "",
      title : "",
      details: "",
      slider: 1,
      image_link: "",
      caption_image: "",
      author: "",
      deskripsi: "",
      tgl_berita : this.$date().valueOf(),
      uraian_surat_tugas : "",
      tgl_pelaksanaan : "",
      jumlah_hari_pelaksanaan : 0,
      source : [],
      dasar_surat_tugas : "",
      category : "",
      jenis_tujuan : "",
      pegawais : [],
      categories : [],
      tags : [],
      selected: [], // Must be an array reference!
      upts: [],
      images: [],
      status: 1,
      submitString: "Submit",
      isSubmitable : true,
      show: true,
      editorSettings: {
        setup: function (editor) {
          console.log('setup', editor);

          // Register the custom button directly within the setup method
          editor.ui.registry.addButton('test', {
            icon: 'image',
            onAction: function () {
              console.log('test button clicked');
              appName.imageSourceClick = 'tiny';
              appName.galeriModal = true;
            },
          });

          editor.on('init', function () {
            console.log('setup init', editor);
          });
        },
        height: 700,
        menubar: false,
        contextmenu: false,
        plugins: [
          'advlist  lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'test | undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify bullist numlist | \
            outdent indent | removeformat | '
      },
      maxLengthInCars: 140,
      imageSourceClick :'tiny',
      showDate : false,
      schedule: 0,
      editorName:"",
      tags2: []
    }
  },
  created(){
    appName = this;
  },
  mounted(){
  this.loadData();
  this.showNews();
    let us = JSON.parse(this.$store.state.users);
    this.editorName=us.name;
  },

  // watch: {
  //   source: function(oldVal, newVal) {
  //     console.log(JSON.stringify(oldVal) + " " + JSON.stringify(newVal));
  //     // console.log(this.tags)
  //   }
  // },
  methods: {
    addTag(newTag) {
      // const tag = {
      //   title: newTag,
      //   // you'll need to add other items specific to your objects        
      // };
      this.tags.push(newTag);
      this.source = this.source.length> 0 ? this.source +", "+newTag : newTag ;
    },
    checkSchedule : function(value, event) {
        if(event.target.checked) {
          console.log('The checkbox is clicked')
          this.showDate = true
          this.schedule = 1
        }   
        else{
          this.showDate = false
          this.schedule = 0
        }
    },
    setTinyContentNew: function() {
      if(this.new_image){
        const caption_img = this.$refs.new_image_caption.innerHTML;
       if(this.imageSourceClick==='tiny'){
          this.galeriModal = false;
          const string = "<br><img src='"+this.new_image+"' style='width:100%' /><p>"+caption_img+"</p>";
          tinymce.activeEditor.insertContent(string);
          console.log(string);
        }
        else{
          this.galeriModal = false;
          this.caption_image = caption_img;
          this.image_link = this.new_image
        }
      }
      console.log(this.imageSourceClick)
      
    },
    setTinyContent: function(content) {
      if(this.imageSourceClick==='tiny'){

        this.galeriModal = false;
        const string = "<br><img src='"+content.image+"' style='width:100%' /><p>"+content.image_caption+"</p>";
        tinymce.activeEditor.insertContent(string);
        console.log(content);
      }
      else{

        this.galeriModal = false;
        this.caption_image = content.image_caption;
          this.image_link = content.image
      }
      console.log(this.imageSourceClick)
    },
    handleEditorInit() {
      console.log('Editor initialized');
    },
    processJSON(json) {
        return json;
    },
    getData(obj){
        this.totals += +obj.product_price * +this.qty;
        this.barangs.push(obj);
        this.$refs.autocomplete.setValue('');
        console.log(obj);
    },  
    loadTags(){
      if(this.source.length>2)
     { 
        axios.get(process.env.VUE_APP_BASE_URL+"tags")
        .then((response) => {
              response.data.forEach(obj=> this.tags.push(obj.text));
            });
      }

    },
    setVal () {
      // 设置初始值
      this.$refs.editer.run('code', '这里是富文本的初始值')
    },
    getVal () {
      // 获取初始值
      this.$refs.editer.run('code')
    },
    initLoadImage(){
      this.search = null;
      this.searchImage();
    },
    searchImage(){
      console.log(this.search.length)
      if(this.search.length>2){
        axios.get(process.env.VUE_APP_BASE_URL+"images?search="+this.search)
          .then((response) => {
           this.images = [];
           this.images = response.data;
        });
      }
      else{
        axios.get(process.env.VUE_APP_BASE_URL+"images")
          .then((response) => {
            this.images = response.data;
        });
      }
    },
    onChange(event) {
      // this.filelist = [...this.$refs.file.files];

      var fileData = event.target.files[0];
      // this.file_bukti_pembayaran_taxi_text = fileData.name;
      this.new_image_file = fileData;
      this.imgUpload();
      // this.placeholder10 = fileData.name;
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    imgUpload: function(){
      this.loadingUpload = true;
      const formData = new FormData();   
      if(this.new_image_file.name){
        formData.append('image', this.new_image_file, this.new_image_file.name) 
      }
    
      axios.post(process.env.VUE_APP_BASE_URL+"news/upload-image", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            this.loadingUpload = false;
            if(response.status==200){
              this.new_image_src = response.data.image
              this.new_image = response.data.image
            }
            else{
              this.modalError = true;
            }            
        })
    },
    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"tags")
        .then((response) => {
              
              // const datas = response.data;
              response.data.forEach(obj=> this.tags.push(obj.text));
            });

      axios.get(process.env.VUE_APP_BASE_URL+"tags2")
        .then((response) => {
              
              this.tags2 = response.data;
              // response.data.forEach(obj=> this.tags.push(obj.text));
            });

      axios.get(process.env.VUE_APP_BASE_URL+"categories")
        .then((response) => {
              
              const datas = response.data;
              this.categories = response.data;
              // response.data.forEach(obj=> this.pejabats.push(obj.nama_pegawai));
            });



      this.searchImage();
    },

    updateNews: function(event){
      this.submitString = 'Loading...'
      this.isSubmitable = false
      const sourceArray = [];
      this.source.forEach(obj=> sourceArray.push(obj.value));
      const newSource2 = sourceArray.join(',')
      console.log(newSource2)
      const newTglBerita = this.$date((this.tgl_berita)).format('YYYY-MM-DD HH:mm:ss') ?? "";
      console.log(newTglBerita)
      const news = { 
                          title: this.title, 
                          details: this.details, 
                          tgl_berita: newTglBerita, 
                          deskripsi: this.deskripsi,
                          category_id: this.category,
                          source: newSource2,
                          image_link: this.image_link,
                          image_caption: this.caption_image,
                          status: this.status,
                          author: this.author,
                          editor: this.editorName,
                          slider: this.slider,
                          // notif: this.notif,
                          id: this.$route.params.id_news
                          };



      // alert(JSON.stringify(pekerjaan));
      axios.post(process.env.VUE_APP_BASE_URL+"news/update", news)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/news');
            }
            else{
              this.modalError = true;
            }            
        })
    },
    showNews: function(event){
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"news/"+this.$route.params.id_news)
        .then((response) => {            
              this.title=response.data.title;
              this.category=response.data.category_id;
              this.caption_image=response.data.image_caption;
              this.image_link=response.data.image;
              this.deskripsi=response.data.description;
              let source = response.data.source;
              if(source.length>0) {
                let source2=source.split(",");
                let newArray = []
                source2.forEach(i=>{
                  let obj = {}
                  obj['key'] = this._.kebabCase(i),
                  obj['value'] = i
                  newArray.push(obj)
                })
                this.source = newArray
                console.log(newArray);
              }
              this.author=response.data.user_name;
              this.editor=response.data.editor;
              this.slider=response.data.slider;
              this.details=response.data.details;
              this.showDate=response.data.schedule===1??true; 
              this.tgl_berita = response.data.created_at
              // this.jenis_tujuan=response.data.jenis_tujuan;
        })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>